// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-grid-js": () => import("./../../src/pages/about-grid.js" /* webpackChunkName: "component---src-pages-about-grid-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../src/pages/index-old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-index-v-2-js": () => import("./../../src/pages/index-v2.js" /* webpackChunkName: "component---src-pages-index-v-2-js" */),
  "component---src-pages-index-v-4-js": () => import("./../../src/pages/index-v4.js" /* webpackChunkName: "component---src-pages-index-v-4-js" */),
  "component---src-pages-index-v-5-js": () => import("./../../src/pages/index-v5.js" /* webpackChunkName: "component---src-pages-index-v-5-js" */),
  "component---src-pages-jobs-js": () => import("./../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-modal-projectstep-design-js": () => import("./../../src/pages/modal-projectstep-design.js" /* webpackChunkName: "component---src-pages-modal-projectstep-design-js" */),
  "component---src-pages-projects-js": () => import("./../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projekt-starten-js": () => import("./../../src/pages/projekt-starten.js" /* webpackChunkName: "component---src-pages-projekt-starten-js" */),
  "component---src-pages-projektablauf-beratung-js": () => import("./../../src/pages/projektablauf-beratung.js" /* webpackChunkName: "component---src-pages-projektablauf-beratung-js" */),
  "component---src-pages-projektablauf-design-js": () => import("./../../src/pages/projektablauf-design.js" /* webpackChunkName: "component---src-pages-projektablauf-design-js" */),
  "component---src-pages-projektablauf-implementierung-js": () => import("./../../src/pages/projektablauf-implementierung.js" /* webpackChunkName: "component---src-pages-projektablauf-implementierung-js" */),
  "component---src-pages-projektablauf-kickoff-js": () => import("./../../src/pages/projektablauf-kickoff.js" /* webpackChunkName: "component---src-pages-projektablauf-kickoff-js" */),
  "component---src-pages-projektablauf-konzept-js": () => import("./../../src/pages/projektablauf-konzept.js" /* webpackChunkName: "component---src-pages-projektablauf-konzept-js" */),
  "component---src-pages-projektablauf-launch-js": () => import("./../../src/pages/projektablauf-launch.js" /* webpackChunkName: "component---src-pages-projektablauf-launch-js" */),
  "component---src-pages-projektablauf-prototyp-js": () => import("./../../src/pages/projektablauf-prototyp.js" /* webpackChunkName: "component---src-pages-projektablauf-prototyp-js" */),
  "component---src-pages-projektablauf-support-js": () => import("./../../src/pages/projektablauf-support.js" /* webpackChunkName: "component---src-pages-projektablauf-support-js" */),
  "component---src-pages-projektablauf-testing-js": () => import("./../../src/pages/projektablauf-testing.js" /* webpackChunkName: "component---src-pages-projektablauf-testing-js" */),
  "component---src-pages-projekte-infos-js": () => import("./../../src/pages/projekte-infos.js" /* webpackChunkName: "component---src-pages-projekte-infos-js" */),
  "component---src-pages-projekte-js": () => import("./../../src/pages/projekte.js" /* webpackChunkName: "component---src-pages-projekte-js" */),
  "component---src-pages-start-js": () => import("./../../src/pages/start.js" /* webpackChunkName: "component---src-pages-start-js" */),
  "component---src-pages-thank-you-js": () => import("./../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-job-js": () => import("./../../src/templates/job.js" /* webpackChunkName: "component---src-templates-job-js" */),
  "component---src-templates-project-js": () => import("./../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

